import React, { useEffect, useState } from 'react';
import braintree from 'braintree-web';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { GET_BRAINTREE_TOKEN } from 'graphql/cart/payment-method/braintree';
import { SET_PAYMENT_METHOD_BRAINTREE } from 'graphql/cart/payment-method';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { cartIdVar, cartVar, isPaymentMethodSetVar } from 'ui/page/checkout-page/checkout-state';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { AcceptedCards } from '../credit-card-form/accepted-cards';
import { IPaymentMethodParams } from '../payment-options';
import styles from './style.css';

export const Braintree = ({ setBeforePlaceOrder }: IPaymentMethodParams) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const [error, setError] = useState('');
    const [getBraintreeToken] = useMutation(GET_BRAINTREE_TOKEN);
    const [savePaymentMethod, { loading }] = useMutation(SET_PAYMENT_METHOD_BRAINTREE, {
        onError: (error) => {
            isPaymentMethodSetVar(false);
            throw new Error(error.message);
        },
        onCompleted: (data) => {
            cartVar(data.setPaymentMethodOnCart.cart);
        },
    });
    useEffect(() => {
        const setupBraintree = async (braintreeToken: string) => {
            const client = await braintree.client.create({
                authorization: braintreeToken,
            });
            const hostedFields = await braintree.hostedFields.create({
                client,
                fields: {
                    cardholderName: {
                        selector: '#cardholderNameContainer',
                    },
                    number: {
                        selector: '#cardNumberContainer',
                    },
                    cvv: {
                        selector: '#cvvContainer',
                    },
                    expirationDate: {
                        selector: '#expiryDateContainer',
                    },
                },
            });
            setBeforePlaceOrder(() => async () => {
                try {
                    const payload = await hostedFields.tokenize();
                    savePaymentMethod({
                        variables: {
                            cartId,
                            paymentMethodCode: 'braintree',
                            paymentMethodNonce: payload.nonce,
                            isActivePaymentTokenEnabler: false,
                        },
                    });
                } catch (error) {
                    setError(error);
                }
            });
        };
        getBraintreeToken({
            variables: {
                cartId,
            },
            onCompleted: (data) => {
                setupBraintree(data.createBraintreeClientToken);
            },
        });
    }, []);
    const formTitle = t('Credit Card');
    return (
        <>
            { !loading ? (
                <form id="braintree-form" className="braintree">
                    {formTitle && (
                        <div className={classes(fStyles.formHeader)}>
                            <div className={fStyles.formNormalTitle}>{t(formTitle)}</div>
                        </div>
                    )}
                    <div className={fStyles.formField}>
                        <label htmlFor="cardholderName">Cardholder Name</label>
                        <div id="cardholderNameContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="cardNumber">Card Number</label>
                        <div id="cardNumberContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="expiryDate">Expiry Date</label>
                        <div id="expiryDateContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="cvv">CVV</label>
                        <div id="cvvContainer" className={styles.braintreeInputClass} />
                    </div>
                    <AcceptedCards />
                    { error && (
                        <div className={fStyles.error}>
                            { error }
                        </div>
                    ) }
                </form>
            ) : (
                <StandardLoadingImage />
            )}
        </>
    );
};
